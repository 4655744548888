import React     from 'react';
import PropTypes from 'prop-types';
import { Link }  from 'gatsby';

import * as S from './styles';

const DetailsField = (props) => (
  <li>
    <S.DetailsLabel>{props.label}</S.DetailsLabel>
    {props.link
      ? <Link to={props.link}>{props.value}</Link>
      : <div>{props.value}</div>
    }
  </li>
);

DetailsField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default DetailsField;
