import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Card = styled.article`
  width: 95vw;
  max-width: 800px;
  height: auto;
  background: #fff;
  margin: 0 auto;
  border-radius: 3px;
  padding: 25px 5px 0 5px;
  img {
    margin-bottom: 0;
  }
  @media all and (max-width: 900px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  margin: 10px auto;
  max-width: 365px;
`;

export const DetailsList = styled.ul`
  list-style-type: none;
  margin: 0;
  li {
    margin-bottom: 12px;
    padding-left: 10px;
    border-left: 2px solid ${({theme}) => theme.colors.brandColor};
  }
`;

export const DetailsLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: black;
`;

export const Info = styled.p`
  font-size: 12px;
  font-style: italic;
  text-align: right;
`;

// TODO extract
export const Button = styled.a`
    // TODO don't hardcode #fff 
    background-color: ${({theme, primary}) => primary ? theme.colors.brandColor : theme.colors.baseColor};
    color: ${({theme, primary}) => primary ? '#fff' : theme.colors.brandColor};
    width: 100%;
    display: block;
    text-align: center;
    margin: 18px 0;
    padding: 12px 0;
    border: 1px solid ${({theme}) => theme.colors.brandColor};
    border-radius: 2px;
    transition: background-color 0.2s;
    &:hover {
      background-color: ${({theme, primary}) => primary ? theme.colors.baseColor : theme.colors.brandColor};
      color: ${({theme, primary}) => primary ? theme.colors.brandColor : '#fff'};
    }
`;