import React                   from 'react';
import PropTypes               from 'prop-types';
import Img                     from 'gatsby-image';
import Helmet                  from 'react-helmet';
import { Col, Container, Row } from 'react-grid-system';

import * as S       from './styles';
import DetailsField from './DetailsField';

// TODO read from config
const emailAddress = 'juwelier-leiber@t-online.de';

class ItemCardDetails extends React.Component {

  static propTypes = {
    item: PropTypes.object.isRequired,
    itemImage: PropTypes.object.isRequired,
    manufacturer: PropTypes.object,
  };

  render() {
    const item = this.props.item;
    const itemImage = this.props.itemImage;
    let manufacturer = null;
    if(this.props.manufacturer) {
      manufacturer = this.props.manufacturer.frontmatter;
    }
    const emailMessage = `
      mailto:${emailAddress}?subject=Guten Tag. Ich interessiere mich für folgenden Ring: ${item.itemNumber}&body=Ich interessiere mich für folgenden Ring: ${item.itemNumber} von ${item.manufacturer}. (${typeof window !== 'undefined' ? window.location : this.props.location})
    `;
    return (
      <S.Container>
        {/*TODO implement via SEO-Component*/}
        <Helmet
          title={item.itemNumber}
        />
        <S.Card>
          <Container fluid>
            <Row align='center'>
              <Col md={12} lg={6} style={{ textAlign: 'center' }}>
                <S.ImageContainer>
                  <Img fluid={itemImage} alt={item.itemNumber}/>
                </S.ImageContainer>
              </Col>
              <Col md={12} lg={6}>
                <h2>{item.category} von {item.manufacturer}</h2>
                {item.description && <p>{item.description}</p>}
                <S.DetailsList>
                  {manufacturer &&
                    <DetailsField label={'Hersteller'} value={manufacturer.title} link={manufacturer.slug}/>
                  }
                  {item.itemNumber && <DetailsField label={'Artikelnummer'} value={item.itemNumber}/>}
                  {item.materials && <DetailsField label={'Material'} value={item.materials}/>}
                  {item.diamond && <DetailsField label={'Diamantbesetzung'} value={item.diamond}/>}
                  {item.price && <DetailsField label={'Preis'} value={`${parseInt(item.price) === 0 ? 'Auf Anfrage' : item.price + ' €*'}`}/>}
                </S.DetailsList>
                <S.Button href={emailMessage}>Kontaktieren</S.Button>
                {/*<a href={`${configuratorBaseUrl}#${ringNumber}`} target='_blank'>Konfigurator</a>*/}
                {item.price && parseInt(item.price) !== 0
                  ? <S.Info>*Paarpreis. Kann je nach Goldpreis variieren</S.Info>
                  : null
                }
              </Col>
            </Row>
          </Container>
        </S.Card>
      </S.Container>
    )
  }
}

export default ItemCardDetails;
