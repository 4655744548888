import React           from 'react';
import { graphql }     from 'gatsby'
import ItemCardDetails from '../../components/ItemCardDetails';
import Wrapper         from '../../components/Wrapper';
import DefaultLayout   from '../../layouts/Default';

class Item extends React.Component {
  render() {
    let isModal = false;
    if(
      typeof window !== 'undefined' &&
      window.___PAGE_INITIAL_RENDER_COMPLETE
    ) {
      isModal = true;
    }
    return (
      <DefaultLayout location={this.props.location} isModal={isModal} items={this.props.data.items.edges}>
        <Wrapper>
          {this.props.data.itemImage
            ? <ItemCardDetails manufacturer={this.props.data.manufacturer} item={this.props.data.item} itemImage={this.props.data.itemImage.childImageSharp.fluid}/>
            : <div>Item not found</div>
          }
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default Item;

export const query = graphql`
  query ItemDetailsQuery($slug: String!, $imagePath: String!, $subCategory: String!, $manufacturerPath: String!) {
    item: itemsXlsxTrauringe(fields: {slug: {eq: $slug}}) {
      itemNumber
      manufacturer
      category
      subCategory
      diamond
      materials
      description
      price
      fields {
        slug
        categoryPath
        subCategoryPath
        manufacturerPath
        fullPath
      }
    }
    itemImage: file(relativePath: {regex: $imagePath}) {
      name
      childImageSharp {
        fluid(maxWidth: 365, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    items: allItemsXlsxTrauringe(filter: {subCategory: {eq: $subCategory}}) {
      edges {
        node {
          fields {
            slug
            fullPath
          }
        }
      }
    }
    manufacturer: markdownRemark(frontmatter: {slug: {eq: $manufacturerPath}}) {
      frontmatter {
        title
        logoPath
        slug
      }
    }
  }
`;